import { SliderLinkComp } from "@components/PropertyCard/PropertyCard"
import Slider from "@components/elements/Slider/Slider"
import useInteractive from "@hooks/useInteractive"
import { offplanResultsURL } from "@lib/urls"
import { sort } from "@lib/utils"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import "./DeveloperSlider.scss"
import GGFXImage from "@components/elements/GGFXImage"

const DeveloperSlider = ({ module }) => {
  const { hasInteracted } = useInteractive()

  const data = useStaticQuery(graphql`
    query {
      allStrapiDeveloper(filter: { publish: { eq: true } }) {
        nodes {
          ...developerFragment
        }
      }
    }
  `)

  if (!hasInteracted) return null

  const developers = data?.allStrapiDeveloper?.nodes
  const sortedDevelopers = developers
    ?.sort((a, b) => sort(a?.rank, b?.rank))
    .slice(0, 10)

  return (
    <div className="developer-slider-wrap section-m">
      <Container className="developer-slider-container">
        <p className="heading">{module?.heading}</p>
        <div className="slider-section">
          <Slider size="xl-alt">
            {sortedDevelopers?.map((developer) => {
             
              const propertyURL = `${offplanResultsURL}developed-by-${developer.slug}/`

              return (
                <div className="developer-card" key={developer.id}>
                  <SliderLinkComp
                    className="developer-image img-zoom"
                    url={propertyURL}
                    slider
                  >
                    <GGFXImage
                      ImageSrc={developer?.logo}
                      altText={developer?.name}
                      imagetransforms={developer?.ggfx_results}
                      renderer="srcSet"
                      imagename="developer.logo.slider_logo"
                      strapiID={developer?.strapi_id}
                      lazyLoading
                    />
                    {/* <img
                      src={developer?.logo?.url}
                      alt="developer-logo"
                      loading="lazy"
                    /> */}
                  </SliderLinkComp>
                </div>
              )
            })}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default DeveloperSlider
